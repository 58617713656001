<template>
  <div class="Login">
    <img src="@/assets/Login.png" class="ImageBak">
    <div class="worp">
      <div class="UsPwd">
        <i class="el-icon-user-solid"></i>
        <el-input type="text" placeholder="请输入账号" v-model="UserName" />
      </div>
      <div class="UsPwd">
        <i class="el-icon-lock"></i>
        <el-input type="password" placeholder="请输入密码" v-model="PassWord" />
      </div>
      <el-button type="primary" size="small"
        style="width: 80px; font-size: 15px; margin-top: 5px; margin-left:150px;" @click="SubMit">
        登 录</el-button>
    </div>
  </div>
</template>

<script>

import commonApi from '@/api/commonApi.js'

export default {
  data () {
    return {
      UserName: '',
      PassWord: ''
    }
  },
  methods: {
    SubMit: function () {
      const vm = this
      const data = {
        username: this.UserName,
        password: this.PassWord
      }
      if (this.UserName === '' || this.UserName === null) {
        this.$message.error('请输入账号')
      } else if (this.PassWord === '' || this.PassWord === null) {
        this.$message.error('请输入密码')
      } else {
        commonApi.adminUser(data).then((res) => {
          if (res) {
            sessionStorage.setItem('token', res)
            vm.$router.push({ name: 'Home' })
          } else {
            this.$message.error('用户名或密码错误')
          }
        }).catch((response) => {
          console.log(response)
        })
      }
    }
  }
}
</script>

<style>
.Login {
  width: 100%;
  height: 100vh;
}
.ImageBak {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
}
.worp {
  width: 400px;
  height: 200px;
  margin: 0 auto;
  padding-top: 50px;
  background: #ffffff;
  border: 1px solid #f2f6fc;
  box-shadow: darkgrey 0px 0px 2px 2px;
  border-radius: 5px;
  position: relative;
  z-index: 999;
  top: 30vh;
}
.UsPwd {
  width: 300px;
  margin: 0 auto;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
i {
  margin-right: 10px;
}
input {
  width: 250px;
  height: 18px;
  line-height: 18px;
  border: 0;
  padding: 10px 10px;
  border-bottom: 2px solid #99ffff;
  background-color: rgba(0, 0, 0, 0);
}
</style>
